import no from '@/locales/no.json'
import en from '@/locales/en.json'
import {useLocaleStore} from '~/stores/locale';
export default defineI18nConfig(() => {

    const localeStore = useLocaleStore()

    return {
        legacy: false,
        locale: localeStore.locale || 'no',
        messages: {
            en,
            no
        }
    };
})
